<template>
    <div class="img-cropper">
        <b-media @click="$refs.imageInput.click()">
            <template #aside>
                <b-avatar ref="logo" :src="image.image != null ? image.image : doc_icon" variant="light-primary" size="100px" rounded/>
            </template>

            <div class="d-flex flex-wrap">
                <input accept=".png,.jpeg,.jpg" ref="imageInput" type="file" class="d-none" @input="imageRenderer">
            </div>
        </b-media>

        <!-- image cropper model -->
        <b-modal id="crop-modal" ref="crop-modal" centered title="Crop Image" hide-footer :no-close-on-backdrop="true" @hide="resetModal">
            <template>
                <div>
                    <div class="">
                        <section class="cropper-area">
                            <div class="img-cropper">
                                <vue-cropper ref="cropper" :src="temp.image" :aspectRatio="1/1" />
                            </div>
                            <div class="pt-2 text-center float-left">
                                <b-button variant="primary" class="mr-2 text-capitalize" @click.prevent="cropImage">CROP</b-button>
                                <b-button variant="danger" class="mr-2 text-capitalize" @click.prevent="reset">RESET</b-button>
                            </div>
                        </section>
                    </div>
                </div>
            </template>        
        </b-modal>

    </div>
</template>

<script>
import {
    BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile
} from 'bootstrap-vue';
import VueCropper from 'vue-cropperjs';
import Bus from "../../../event-bus";
import 'cropperjs/dist/cropper.css';

export default{
    name: 'AddImage',
    components: {
        BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, BFormFile,VueCropper
    },
    props: ['index','image'],
    data(){
        return{
            // image vars
            temp: {
                image:null,
                name:''
            },
            /*image: {
                image:null,
                name:''
            },*/
            doc_icon: require('@/assets/images/doc.png'),
            
        }
    },
    methods: {
        showImg(){
            this.$refs['crop-modal'].show();
        },

        resetModal(){
            
            this.$refs.imageInput.value=null;
            this.temp.image = null;
            this.temp.name="";

            this.$refs['crop-modal'].hide();
        },

        cropImage() {
            this.image.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.image.name = this.temp.name;
            
            this.$refs['crop-modal'].hide();

            var data = {
                image : this.image,
                index : this.index
            }
            
            this.$emit('uploadImage', data);
        },

        reset() {
            this.$refs.cropper.reset();
        },

        imageRenderer(event){
            var input = event.target;
            var files = event.target.files

            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var image = input.files[0];

                if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Please upload .png, .jpeg, .jpg images only',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$refs.imageInput.value=null;
                } else if (image.size > 2097152) {
                    Swal.fire({
                        position: 'center',
                        icon: 'warning',
                        title: 'Maximum 2 MB files allowed to be upload.',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$refs.imageInput.value=null;
                } else {
                    reader.onload = (e) => {
                        this.temp.image = e.target.result;
                        this.temp.name = image.name;
                        //this.modelbox = 'myImage';
                        this.showImg();
                        
                    }
                }
            }
        }
            
    },
    mounted(){}
}
</script>