<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card>
      <b-row>
        <b-col md=12>

          <div class="demo-inline-spacing float-right ">
            
              <b-button
                variant="danger"
                class="mt-0 mb-0"
                @click="$router.push({name:'quality-safety-locations', params: { site_id: site ? site : null } })"
                v-if="disabledOptions == false"
              >
              <feather-icon
                  icon="GridIcon"
                  class="mr-25"
                />
                <span>Locations</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12"> 
          <h4 class="card-title float-left"> Quality & Safety Assessment</h4> 
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
            <b-form-group
                label="Project Site"
                class="required"

              >
                <b-form-select v-model="site" @change="siteChange()" :disabled="disabledOptions">

                  <b-form-select-option value="" disabled>Select</b-form-select-option>
                  
                  <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                  
                  
                </b-form-select>
                  
              </b-form-group>
            
        </b-col>

        <b-col md="4">
            <b-form-group label="Shift" class="required">
                <b-form-select v-model="shift" @change="itemData()" :disabled="disabledOptions">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                </b-form-select>
            </b-form-group>                                
        </b-col>

        <b-col md="4" >

          <b-form-group
            label="Date"
            class="required"

          > 
            <b-form-datepicker
            
              v-model="date"
              :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
              locale="en"
              @input="itemData()"
              :disabled="disabledOptions"
            />

              
          </b-form-group>
        </b-col>

        
      </b-row>
      
      <b-row>
        <b-col md="4">
            <b-form-group label="Month of Audit" class="required">
                <b-form-select v-model="month_of_audit" @change="itemData()" :disabled="disabledOptions">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="audit" v-for="(audit,i) in auditMonths" :key="i">{{audit}}</b-form-select-option>
                </b-form-select>
            </b-form-group>                                
        </b-col>

        <b-col md="4">
          <b-form-group label="Description" class="required">

            <b-form-input                
              v-model="description"
              type="text"
              autocomplete="off"
              placeholder="3 to 50 characters"
              :disabled="disabledOptions"
            />

          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Inspected By" class="required">
            <b-form-input                
              v-model="inspected_by"
              type="text"
              autocomplete="off"
              :disabled="disabledOptions"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-card>
    <b-form @submit="formSubmit">
      
      <h4 class="text-center mb-2 mt-1" v-if="(checklistData.length > 0) && (site != '') && (shift != '') && (month_of_audit != '')">
        Location Information
      </h4>

      <b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">
        
        <div v-if="((site != '') && (shift != '') && (month_of_audit != ''))">
          <b-table
            responsive
            show-empty
            ref = "refUserListTable"
            :class = "list.no_of_levels > 4 ? 'position-relative midTable' : 'position-relative'"
            primary-key = "id"
            empty-text = "No records found"
            :fields = "getTableHeader(list.no_of_levels)"
            :items = "list.item_data"
            v-if="site != ''"
          >

          <template slot="thead-top" slot-scope="data">
            
            <tr>
              <th :colspan="list.no_of_levels + 2">

                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 float-right"
                  @click="removeLocation(i)"
                  v-if="((checklistData.length > 0 && checklistData[i].db_location == false))"
                  :disabled="(checkdisabled() && disabledOptions)">
                 Remove
                </b-button>

                <b-button
                  size="sm"
                  variant="danger"
                  class="mr-1 float-right"
                  @click="removeDBLocation(i,checklistData[i])"
                  v-if="((checklistData.length > 0 && checklistData[i].db_location == true))"
                  :disabled="(checkdisabled() && disabledOptions)">
                 Remove
                </b-button>

              </th>
            </tr>
            <tr>
              
              <th colspan="1"> 
                <b-form-select v-model="list.location" @change="locationChange(list.location,i)" :disabled="(checkdisabled() && disabledOptions)">

                  <b-form-select-option value="" disabled="true">Select Location</b-form-select-option>
                  
                  <b-form-select-option :value="location._id" v-for="location in locations" :key="location._id" :disabled="locationDisable(location._id)">{{location.location_name | capitalize}}</b-form-select-option>
                  
                </b-form-select>
              </th>
             
             <th colspan="1" v-if="list.no_of_levels >= 1">
                
                <b-row>
                  <b-col md="12">

                    <b-form-input                
                      v-model="list.level_1"
                      type="text"
                      autocomplete="off"
                      placeholder="Level 1"
                      v-if="list.item_data.length > 0"

                    />

                  </b-col>
                  <b-col md="12">

                    <!-- <b-button
                      size="sm"
                      variant="danger"
                      class="mr-1 float-right"
                      
                      v-if="list.item_data.length > 0 && list.no_of_levels == 1">
                     Remove
                    </b-button> -->

                  </b-col>
                </b-row>
                
            </th>
             <th colspan="1" v-if="list.no_of_levels >= 2">
              
               <b-row>
                  <b-col md="12">

                    <b-form-input                
                      v-model="list.level_2"
                      type="text"
                      autocomplete="off"
                      placeholder="Level 2"
                      v-if="list.item_data.length > 0"
                    />

                  </b-col>
                  <b-col md="12">
                    
                    <b-button
                      size="sm"
                      variant="danger"
                      class="mr-1 mt-1 float-right"
                      @click="removeLevel(i,'level_2')"
                      v-if="list.item_data.length > 0 && list.no_of_levels == 2"
                      :disabled="disabledOptions">
                     <feather-icon icon="XIcon" />
                    </b-button>
                  

                  </b-col>
                </b-row>

             </th>
             <th colspan="1" v-if="list.no_of_levels >= 3">
              
               <b-row>
                  <b-col md="12">

                    <b-form-input                
                      v-model="list.level_3"
                      type="text"
                      autocomplete="off"
                      placeholder="Level 3"
                      v-if="list.item_data.length > 0"
                    />

                  </b-col>
                  <b-col md="12">

                    <b-button
                      size="sm"
                      variant="danger"
                      class="mr-1 mt-1 float-right"
                      @click="removeLevel(i,'level_3')"
                      v-if="list.item_data.length > 0 && list.no_of_levels == 3"
                      :disabled="disabledOptions">
                     <feather-icon icon="XIcon" />
                    </b-button>

                  </b-col>
                </b-row>
             </th>
             <th colspan="1" v-if="list.no_of_levels >= 4">
              
               <b-row>
                  <b-col md="12">

                    <b-form-input                
                      v-model="list.level_4"
                      type="text"
                      autocomplete="off"
                      placeholder="Level 4"
                      v-if="list.item_data.length > 0"
                    />

                  </b-col>
                  <b-col md="12">

                    <b-button
                      size="sm"
                      variant="danger"
                      class="mr-1 mt-1 float-right"
                      @click="removeLevel(i,'level_4')"
                      v-if="list.item_data.length > 0 && list.no_of_levels == 4"
                      :disabled="disabledOptions">
                     <feather-icon icon="XIcon" />
                    </b-button>

                  </b-col>
                </b-row>
             </th>
             <th colspan="1" v-if="list.no_of_levels >= 5">
              
               <b-row>
                  <b-col md="12">

                    <b-form-input                
                      v-model="list.level_5"
                      type="text"
                      autocomplete="off"
                      placeholder="Level 5"
                      v-if="list.item_data.length > 0"
                    />

                  </b-col>
                  <b-col md="12">

                    <b-button
                      size="sm"
                      variant="danger"
                      class="mr-1 mt-1 float-right"
                      @click="removeLevel(i,'level_5')"
                      v-if="list.item_data.length > 0 && list.no_of_levels == 5"
                      :disabled="disabledOptions">
                     <feather-icon icon="XIcon" />
                    </b-button>

                  </b-col>
                </b-row>
             </th>
             <th colspan="1" v-if="list.no_of_levels >= 6">
               
               <b-row>
                  <b-col md="12">

                    <b-form-input                
                      v-model="list.level_6"
                      type="text"
                      autocomplete="off"
                      placeholder="Level 6"
                      v-if="list.item_data.length > 0"
                    />

                  </b-col>
                  <b-col md="12">

                    <b-button
                      size="sm"
                      variant="danger"
                      class="mr-1 mt-1 float-right"
                      @click="removeLevel(i,'level_6')"
                      v-if="list.item_data.length > 0 && list.no_of_levels == 6"
                      :disabled="disabledOptions">
                     <feather-icon icon="XIcon" />
                    </b-button>

                  </b-col>
                </b-row>
             </th>

              
              <th colspan="1">
                <b-button
                  size="sm"
                  variant="warning"
                  class="mr-1 float-right"
                  @click="addLevel(i)"
                  v-if="list.item_data.length > 0 && list.no_of_levels < 6"
                  :disabled="(checkdisabled() && disabledOptions)">
                 <feather-icon icon="PlusIcon" />
                </b-button>

              </th>
            </tr>
          </template>

          <template #cell(item_name)="items">

              
              <p>{{items.item.item_name | capitalize}}</p>
         
          </template>

          <template #cell(level_1)="items">

              
              <!-- <b-form-input
                placeholder=""
                v-model="items.item.level_1"
                type="text"
                autocomplete="off"
                @keypress="isNumber($event)"
             /> -->

              <b-form-select v-model="items.item.level_1">
                <b-form-select-option value="" disabled>Select Condition</b-form-select-option>
                <b-form-select-option value="5">Excellent - 5</b-form-select-option>
                <b-form-select-option value="4">Good - 4</b-form-select-option>
                <b-form-select-option value="3">Average - 3</b-form-select-option>
                <b-form-select-option value="2">Below Average - 2</b-form-select-option>
                <b-form-select-option value="1">Fail - 1</b-form-select-option>
                <b-form-select-option value="N/A">N/A</b-form-select-option>
              </b-form-select>
         
          </template>

          <template #cell(level_2)="items">

              
              <!-- <b-form-input
                placeholder=""
                v-model="items.item.level_2"
                type="text"
                autocomplete="off"
                @keypress="isNumber($event)"
             /> -->

             <b-form-select v-model="items.item.level_2">
                <b-form-select-option value="" disabled>Select Condition</b-form-select-option>
                <b-form-select-option value="5">Excellent - 5</b-form-select-option>
                <b-form-select-option value="4">Good - 4</b-form-select-option>
                <b-form-select-option value="3">Average - 3</b-form-select-option>
                <b-form-select-option value="2">Below Average - 2</b-form-select-option>
                <b-form-select-option value="1">Fail - 1</b-form-select-option>
                <b-form-select-option value="N/A">N/A</b-form-select-option>
              </b-form-select>
         
          </template>

          <template #cell(level_3)="items">

              
              <!-- <b-form-input
                placeholder=""
                v-model="items.item.level_3"
                type="text"
                autocomplete="off"
                @keypress="isNumber($event)"
                
             /> -->

              <b-form-select v-model="items.item.level_3">
                <b-form-select-option value="" disabled>Select Condition</b-form-select-option>
                <b-form-select-option value="5">Excellent - 5</b-form-select-option>
                <b-form-select-option value="4">Good - 4</b-form-select-option>
                <b-form-select-option value="3">Average - 3</b-form-select-option>
                <b-form-select-option value="2">Below Average - 2</b-form-select-option>
                <b-form-select-option value="1">Fail - 1</b-form-select-option>
                <b-form-select-option value="N/A">N/A</b-form-select-option>
              </b-form-select>
         
          </template>

          <template #cell(level_4)="items">

              <!-- <b-form-input
                placeholder=""
                v-model="items.item.level_4"
                type="text"
                autocomplete="off"
                @keypress="isNumber($event)"
                
             /> -->

              <b-form-select v-model="items.item.level_4">
                <b-form-select-option value="" disabled>Select Condition</b-form-select-option>
                <b-form-select-option value="5">Excellent - 5</b-form-select-option>
                <b-form-select-option value="4">Good - 4</b-form-select-option>
                <b-form-select-option value="3">Average - 3</b-form-select-option>
                <b-form-select-option value="2">Below Average - 2</b-form-select-option>
                <b-form-select-option value="1">Fail - 1</b-form-select-option>
                <b-form-select-option value="N/A">N/A</b-form-select-option>
              </b-form-select>
         
          </template>

          <template #cell(level_5)="items">

              <!-- <b-form-input
                placeholder=""
                v-model="items.item.level_5"
                type="text"
                autocomplete="off"
                @keypress="isNumber($event)"
                
             /> -->

              <b-form-select v-model="items.item.level_5">
                <b-form-select-option value="" disabled>Select Condition</b-form-select-option>
                <b-form-select-option value="5">Excellent - 5</b-form-select-option>
                <b-form-select-option value="4">Good - 4</b-form-select-option>
                <b-form-select-option value="3">Average - 3</b-form-select-option>
                <b-form-select-option value="2">Below Average - 2</b-form-select-option>
                <b-form-select-option value="1">Fail - 1</b-form-select-option>
                <b-form-select-option value="N/A">N/A</b-form-select-option>
              </b-form-select>
         
          </template>

          <template #cell(level_6)="items">

              <!-- <b-form-input
                placeholder=""
                v-model="items.item.level_6"
                type="text"
                autocomplete="off"
                @keypress="isNumber($event)"
                
             /> -->

              <b-form-select v-model="items.item.level_6">
                <b-form-select-option value="" disabled>Select Condition</b-form-select-option>
                <b-form-select-option value="5">Excellent - 5</b-form-select-option>
                <b-form-select-option value="4">Good - 4</b-form-select-option>
                <b-form-select-option value="3">Average - 3</b-form-select-option>
                <b-form-select-option value="2">Below Average - 2</b-form-select-option>
                <b-form-select-option value="1">Fail - 1</b-form-select-option>
                <b-form-select-option value="N/A">N/A</b-form-select-option>
              </b-form-select>
         
          </template>
        </b-table>
        </div>
      </b-card>

      <b-card class="mt-1"  v-if="site != '' && shift != '' && month_of_audit != ''">
        <b-row>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="mr-1 float-right"
              @click="addMoreCheckList"
              v-if="site != ''"
              :disabled="(checkdisabled() && disabledOptions)"
            >
              Add Location
            </b-button>
                                         
          </b-col>

        </b-row>
        <b-row>
          <b-col md="12">

            <b-form-group
              label="Others"
              class=""
            >
              <b-form-textarea
                placeholder="upto 500 characters"
                rows="3"
                v-model="others"
              />
            </b-form-group>


          </b-col>
        </b-row>

      </b-card>

      <!-- Photo Findings -->


      <h4 class="text-center mb-2 mt-1" v-if="(checklistData.length > 0) && (site != '') && (shift != '') && (month_of_audit != '')">
        Photo Findings
      </h4>
      <b-card no-body class="mb-1" v-if="(checklistData.length > 0) && (site != '') && (shift != '') && (month_of_audit != '')">

        <b-table
          responsive
          sticky-header
          show-empty
          ref = "refUserListTable"
          class = "position-relative bigTable"
          primary-key = "id"
          empty-text = "No records found"
          :fields = "(disabledOptions == true && ledit == false) ? tableColumnsUpdate : tableColumns"
          :items = "photoFindings"
        >
          <template #cell(sno)="items">
              {{items.index + 1}}
         
          </template>

          <template #cell(hazard_category)="items">

              <b-form-select v-model="items.item.hazard_category" :disabled="items.item.db_finding == true">
                <b-form-select-option value="" disabled>Select</b-form-select-option>
                <b-form-select-option value="quality">Quality</b-form-select-option>
                <b-form-select-option value="safety">Safety</b-form-select-option>
                
              </b-form-select>
         
          </template>

          <template #cell(severity_level)="items">
              
              <b-form-select v-model="items.item.severity_level" :disabled="items.item.db_finding == true" @change="updatePhotoStatus(items.item.severity_level,items.index)">

                <b-form-select-option value="" disabled>Select</b-form-select-option>
                
                <b-form-select-option value="good_practice">{{'Good Practice'}}</b-form-select-option>
                <b-form-select-option value="minor">{{'Minor'}}</b-form-select-option>
                <b-form-select-option value="major">{{'Major'}}</b-form-select-option>
                
                
              </b-form-select>
         
          </template>

          <template #cell(location)="items">
              <b-form-input
                placeholder=""
                v-model="items.item.location"
                type="text"
                autocomplete="off"
                :disabled="items.item.db_finding == true"                
             />
         
          </template>

          <template #cell(picture)="items">
              

              <b-avatar ref="img" :src="items.item.picture.image" variant="light-primary" size="100px" v-if="disabledOptions == true && ledit == false" rounded/>

              <Picture :index="items.index" :image="items.item.picture" @uploadImage="uploadImage" v-else />
         
          </template>

          <template #cell(description)="items">
              <b-form-input
                placeholder=""
                v-model="items.item.description"
                type="text"
                autocomplete="off"                
             />
         
          </template>

          <template #cell(action_type)="items">
              <div class="demo-inline-spacing">
                <b-form-radio
                  class="mb-0 mt-1 mb-1"
                  :name="'some-radios' + items.index"
                  value="text"
                  v-model="items.item.action_type"
                  @change="resetAction(items.index)"
                >
                  Text
                </b-form-radio>

                <b-form-radio
                  class="mb-0 mt-1 mb-1"
                  :name="'some-radios' + items.index"
                  value="image"
                  v-model="items.item.action_type"
                >
                  Image
                </b-form-radio>
              </div>
         
          </template>

          <template #cell(action_taken)="items">
              
              
              <b-form-input
                placeholder=""
                v-model="items.item.action_taken"
                type="text"
                autocomplete="off"
                v-if="items.item.action_type == 'text'"                
             />



             <ActionPicture :index="items.index" :image="items.item.action_image" @uploadActionImage="uploadActionImage" v-if="items.item.action_type == 'image'"/>
         
          </template>

          <template #cell(date)="items">
              
              <date-picker v-model="items.item.date" 
              format="DD MMM YYYY" 
              type="date"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=false
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              ></date-picker>
         
          </template>

          <template #cell(status)="items">
              
              <b-form-select v-model="items.item.status">

                <b-form-select-option value="" disabled>Select</b-form-select-option>
                
                <b-form-select-option value="open">{{'Open'}}</b-form-select-option>
                <b-form-select-option value="pending">{{'Pending'}}</b-form-select-option>
                <b-form-select-option value="closed">{{'Closed'}}</b-form-select-option>
                
                
              </b-form-select>
                    
              
         
          </template>

          <template #cell(action)="items">
            
             <b-button
                size="sm"
                variant="danger"
                class="mr-1 float-right"
                @click="removeFinding(items.index)"
                v-if="items.item.db_finding == false"
                :disabled="(checkdisabled() && disabledOptions)">
               Remove
              </b-button>

              <b-button
                size="sm"
                variant="danger"
                class="mr-1 float-right"
                @click="removeDBFinding(items.index,items.item)"
                v-if="items.item.db_finding == true"
                :disabled="(checkdisabled() && disabledOptions)">
               Remove
              </b-button>
         
          </template>

        </b-table>
      </b-card>



      <!-- Photo Findings End-->



      <b-card class="mt-1"  v-if="site != '' && shift != '' && month_of_audit != ''">
      
        <!-- signature structure  -->
        <b-row>
            <b-col offset-md="4" md="4">
              <b-form-group label="Time of Audit" class="required">
                <flat-pickr
                  v-model="time"
                  class="form-control"
                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                class="mr-1 float-right"
                @click="addMoreFindings"
                v-if="checklistData.length > 0"
                :disabled="(checkdisabled() && disabledOptions)"
              >
                Add Findings
              </b-button>
            </b-col>
  

            <b-col cols="12" class="mb-2">
                <h4 class="text-center pb-2">Signature <span style="color:red">*</span></h4>
                <center>
                    <b-media class="" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">                                      
                        <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" rounded style="height: 200px; width: auto"/>

                        <div class="d-flex flex-wrap">
                            <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                        </div>
                    </b-media>
                    <img :src="supervisorSign.image" v-if="supervisorSign.image != null" class="rounded" style="background: #ffffff" width="200px" height="180">
                </center>
                
                <center>
                    <b-button size="sm" variant="danger" class="mt-1 px-2 py-1" v-if="supervisorSign.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions"> 
                        <feather-icon icon="XIcon" class="mediumSize text-white"/>
                    </b-button>
                </center>
            </b-col>
        </b-row>      

        <b-row>
          <b-col>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              class = "mr-1"
              variant="outline-secondary"
              
              @click="goBack()"
            >
              Cancel
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="mr-1"
              v-if="id != null && typeof $route.params.id == 'undefined'"
              @click="discardItem(id)"
              
            >
              Discard Draft
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              type="submit"
              @click="status='draft'"
              v-if="typeof $route.params.id == 'undefined'"
            >
              {{ id == null ? 'Save as Draft' : 'Update Draft'}}
            </b-button>

            

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="mr-1"
              type="submit"
              @click="status='completed'"
            >
              {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              v-if="typeof $route.params.id != 'undefined' && ledit == false"
              @click="openSignature('approver')"
            >
              {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
            </b-button>

            

          </b-col>
        </b-row>

      </b-card>

      




      <!--<b-card class="mt-1 text-center" v-if="site && shift && checklistData.length == 0"> No Item(s) Found. </b-card> -->

    </b-form>

    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
    </b-modal>




  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb,BFormRadio
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import Picture from './AddImage.vue';
import ActionPicture from './ActionImage.vue';
import Bus from "../../../event-bus";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,Picture,BBreadcrumb,BFormRadio,DatePicker,ActionPicture,VueCropper
  },
  
  data() {
    return {
      
      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      site:'',
      sites:[],
      shift:'',
      shifts:[],
      inspected_by : this.$store.getters.currentUser.full_name,
      description:'',
      others:'',
      month_of_audit : '',
      auditMonths:[],
      
      date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      time:null,
      id:null,
      checklistData:[],
      photoFindings:[],
      status:'draft',
      supervisorSign : {
          image:null,
          name:'',
          type: 'supervisor',
          default:'no'
      },
      show_default:false,
      having_default:false,
      disabledOptions:false,
      approverSign : {
          image:null,
          name:'',
          type: 'supervisor',
          default:'no'
      },
      approve_signature_time:null,
      approve_remark:'',
      ledit : false,

      openedSignModel:null,
      locations:[], 

      tableColumns: [
          { key: 'sno', label: 'S.No.', sortable: false , thStyle:  {width: '4%'}},
          { key: 'hazard_category', label: 'Hazard Category', sortable: false , thStyle:  {width: '10%'}},
          { key: 'severity_level', label: 'Severity Level', sortable: false , thStyle:  {width: '10%'}},
          { key: 'location', label: 'Location / Machine', sortable: false , thStyle:  {width: '17%'}},
          { key: 'picture', label: 'Picture Findings', sortable: false , thStyle:  {width: '8%'}},
          { key: 'description', label: 'Brief Description on the Findings', sortable: false , thStyle:  {width: '17%'}},
          { key: 'action_type', label: 'Action Type', sortable: false , thStyle:  {width: '15%'}},
          { key: 'action_taken', label: 'Action Taken', sortable: false , thStyle:  {width: '15%'}},
          /*{ key: 'date', label: 'Date of Completion', sortable: false , thStyle:  {width: '10%'}},
          { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '10%'}},*/
          { key: 'action', label: '', sortable: false , thStyle:  {width: '4%'}},
        ],

      tableColumnsUpdate: [
          { key: 'sno', label: 'S.No.', sortable: false , thStyle:  {width: '4%'}},
          { key: 'hazard_category', label: 'Hazard Category', sortable: false , thStyle:  {width: '10%'}},
          { key: 'severity_level', label: 'Severity Level', sortable: false , thStyle:  {width: '10%'}},
          { key: 'location', label: 'Location / Machine', sortable: false , thStyle:  {width: '12%'}},
          { key: 'picture', label: 'Picture Findings', sortable: false , thStyle:  {width: '8%'}},
          { key: 'description', label: 'Brief Description on the Findings', sortable: false , thStyle:  {width: '12%'}},
          { key: 'action_type', label: 'Action Type', sortable: false , thStyle:  {width: '10%'}},
          { key: 'action_taken', label: 'Action Taken', sortable: false , thStyle:  {width: '10%'}},
          { key: 'date', label: 'Date of Completion', sortable: false , thStyle:  {width: '10%'}},
          { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '10%'}},
          { key: 'action', label: '', sortable: false , thStyle:  {width: '4%'}},
        ],   
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },
    siteChange(){

      this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      this.checklistData = [];
      this.photoFindings = [];
      this.shifts = [];
      this.shift = '';
      this.description = '';
      this.others = '';
      this.locations = [];
      this.getShiftData();
      
      this.getLocations();
      
      if ((this.site != '') && (this.shift != '') && (this.month_of_audit != '')) {
        
        this.itemData();

        
      } else {
        this.month_of_audit = '';
        this.date = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
        this.time = null;
      }
    },
    getShiftData(){
        return this.$store.dispatch(POST_API, {
            data:{
                site:this.site != '' ? this.site : null,
            },
            api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.shifts = data;
                return this.shifts;
            }
        });
    },
    getLocations(){
      return this.$store.dispatch(POST_API, {
            data:{
            site:this.site != '' ? this.site : null,
            },
            api: '/api/all-quality-safety-location'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.locations = this.$store.getters.getResults.data;
                return this.locations;
            }
        });
    },
    itemData(){
      return this.$store.dispatch(POST_API, {
          data:{
             site  : this.site != '' ? this.site : null,
             shift : this.shift != '' ? this.shift : null,
             month_of_audit  : this.month_of_audit,
             date:this.date
          },
          api: '/api/get-quality-safety-assessment-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.id = data._id;
                this.description = data.description;
                this.supervisorSign = data.signature;
                this.checklistData = data.checklistData;
                this.photoFindings = data.photoFindings;
                this.others = data.others;
                this.time = data.time;
                return this.checklistData;
            }
        });
    },
    
    breadCrumb(){

      if(this.$route.params.id){

        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Reports',
        },{
          to:{name:'custom-forms-dashboard'},
          text: 'Form Dashboard',
        },{
          to:{name:'custom-forms-reports'},
          text:'Custom Forms'
        },{
          to:{name:'quality-safety-report'},
          text:'Quality & Safety Assessment',
          
        },{
          to:null,
          text:'Edit',
          active:true
        }];
        return this.filterReportBreadCrum(item);

      }else{

        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:{name:'custom-forms'},
          text: 'Custom Forms',
        },{
          to:null,
          text:'Quality & Safety Assessment',   
          active:true             
        }];
        return item;

      }
      
    },
    createAuditMonth(){
      var arr = [];
      for (var i = 1; i <= 12; i++) {
        var month = moment(i,'M').format('MMMM');
        var number = moment().month(month).format('MM');
        arr.push(month + ' ('+number+')');
      }
      this.auditMonths = arr;
      return this.auditMonths;
    },
    supervisorImageUpload(event){
          var input = event.target;
          var files = event.target.files

          if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var image = input.files[0];

              if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Please upload .jpg, .jpeg, .jpg images only',
                      showConfirmButton: false,
                      timer: 1500
                  });

                  this.$refs.supervisorSInput.value=null;

              } else if (image.size > 2097152) {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Maximum 2 MB files allowed to be upload.',
                      showConfirmButton: false,
                      timer: 1500
                  })
                  this.$refs.supervisorSInput.value=null;
              } else {
                  reader.onload = (e) => {
                      this.supervisorSign.image = e.target.result;
                      this.supervisorSign.name = image.name;
                      this.supervisorSign.type =  'supervisor';
                      this.supervisorSign.default = 'no';
                  }
              }
          }
    },
            
    removeSign(sign){
        this.supervisorSign.image = null;
        this.supervisorSign.name = '';
        this.supervisorSign.default = 'no';
    },

    useDefault(){
      return this.$store.dispatch(POST_API, {
          data:{
              id:this.$store.getters.currentUser._id
          },
          api: '/api/get-default-signature'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data == null || data.default_signature == null) {
                
                this.having_default = false;
                
                }else{

                this.tempSign = {
                    image : data.default_signature,
                    name : 'siganture.png',
                }

                this.having_default = true;
                }
            }
        });
    },

    uploadSign(){
        this.$refs['supervisorSInput'].click();
        this.$refs['signatureModel'].hide();
    },

    openSignature(type){
      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
      
      if (this.openedSignModel == null) {
          if (this.having_default == true) {
              this.show_default = true;
          }else{
              this.show_default = false;
          }
          this.$refs['signatureModel'].show();
          this.openedSignModel = type;
      }
    },

    hideSignature(){
        this.openedSignModel = null;
    },

    clearSignature(){
        this.$refs.signaturePad.clearSignature();
    },

    saveSignature(){
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
          
          if (isEmpty) {
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Signature is required',
                  showConfirmButton: false,
                  timer: 1500
              })
          } else {  
            
            if (this.openedSignModel == 'supervisor') {

              this.supervisorSign.image = data;
              this.supervisorSign.name = 'siganture.png';
              this.supervisorSign.type = 'supervisor';
              this.supervisorSign.default = 'no';

            }else{

              this.approverSign.image = data;
              this.approverSign.name = 'signature.png';
              this.approverSign.type = 'supervisor';
              this.approverSign.default = 'no';
              this.status = 'approved';
              this.actualForm();

            }              

            this.$refs['signatureModel'].hide();
          }
    },

    saveDefault(){

      if (this.openedSignModel == 'supervisor') {
        this.supervisorSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          type :   'supervisor',
          default:'yes'
        }
      }else{

        this.approverSign = {
          image : this.tempSign.image,
          name : 'signature.png',
          default:'yes',
          type:'supervisor'
        }
        this.status = 'approved';
        this.actualForm();

      }

                  
      this.$refs['signatureModel'].hide();
    },
    locationDisable(id){
      var loc_ids = [];
      for (var i = 0; i < this.checklistData.length; i++) {
        if (this.checklistData[i].location != '') {
          loc_ids.push(this.checklistData[i].location);
        }
      }

      if (loc_ids.indexOf(id) >= 0) {
        return true;
      }else{
        return false;
      }

    },
    removeLocation(i){
      this.checklistData.splice(i,1);
      if (this.checklistData.length == 0) {
        this.addMoreCheckList();
      }
    },
    addMoreCheckList(){
      var order = 0;

      if (this.checklistData.length > 0) {
        var lastIndex = this.checklistData.length - 1;
        order = this.checklistData[lastIndex].order + 1;
      }

      var obj = {
          location: '',
          location_name:'',
          level_1 : '',
          level_2 : '',
          level_3 : '',
          
          no_of_levels:3,
          item_data : [],
          db_location : false,
          order:order,
      };

      this.checklistData.push(obj);
    },
    removeDBLocation(i,item){

      var msg = 'Are you sure want to remove this location record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      location  : item.location,
                      date : this.date,
                      site :this.site,
                      shift : this.shift,
                      month_of_audit : this.month_of_audit,
                  },
                  api : "/api/remove-qsa-db-location",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      //localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {

                        this.checklistData.splice(i,1);
                        if (this.checklistData.length == 0) {
                          this.addMoreCheckList();
                        }  
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },
    addLevel(i){
      
      var next_levels = this.checklistData[i].no_of_levels + 1;

      for (var j = 0; j < this.checklistData[i].item_data.length; j++) {
        
        if (next_levels == 2) {
          
          this.checklistData[i].item_data[j].level_2 = '';

        } else if (next_levels == 3) {
          
          this.checklistData[i].item_data[j].level_3 = '';
          
        } else if (next_levels == 4) {
          
          this.checklistData[i].item_data[j].level_4 = '';
          
        } else if (next_levels == 5) {
          
          this.checklistData[i].item_data[j].level_5 = '';

        } else if (next_levels == 6) {
          
          this.checklistData[i].item_data[j].level_6 = '';

        } 
      }

      if (next_levels == 2) {
          
        this.checklistData[i].level_2 = '';

      } else if (next_levels == 3) {
        
        this.checklistData[i].level_3 = '';
        
      } else if (next_levels == 4) {
        
        this.checklistData[i].level_4 = '';
        
      } else if (next_levels == 5) {
        
        this.checklistData[i].level_5 = '';

      } else if (next_levels == 6) {
        
        this.checklistData[i].level_6 = '';

      } 
      this.checklistData[i].no_of_levels = next_levels;
      //console.log(this.checklistData[i]);
    },
    removeLevel(i,level){

      var upd_levels = this.checklistData[i].no_of_levels - 1;

      for (var j = 0; j < this.checklistData[i].item_data.length; j++) {
        
        if (level == 'level_2') {
          
          delete this.checklistData[i].item_data[j].level_2;

        } else if (level == 'level_3') {
          
          delete this.checklistData[i].item_data[j].level_3;
          
        } else if (level == 'level_4') {
          
          delete this.checklistData[i].item_data[j].level_4;
          
        } else if (level == 'level_5') {
          
          delete this.checklistData[i].item_data[j].level_5;

        } else if (level == 'level_6') {
          
          delete this.checklistData[i].item_data[j].level_6;

        } 
      }

      if (level == 'level_2') {
          
        delete this.checklistData[i].level_2;

      } else if (level == 'level_3') {
        
        delete this.checklistData[i].level_3;
        
      } else if (level == 'level_4') {
        
        delete this.checklistData[i].level_4;
        
      } else if (level == 'level_5') {
        
        delete this.checklistData[i].level_5;

      } else if (level == 'level_6') {
        
        delete this.checklistData[i].level_6;

      } 
      this.checklistData[i].no_of_levels = upd_levels;
      //console.log(this.checklistData[i]);

    },
    getTableHeader(level){

      var table = [];

      if (level == 1) {
        table = [
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '30%'}},
          { key: 'level_1', label: '', sortable: false , thStyle:  {width: '50%'}},
          { key: 'action', label: '', sortable: false , thStyle:  {width: '20%'}}, 
        ]

        
      }else if (level == 2){
        table = [
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '30%'}},
          { key: 'level_1', label: '', sortable: false , thStyle:  {width: '25%'}},
          { key: 'level_2', label: '', sortable: false , thStyle:  {width: '25%'}},
          { key: 'action', label: '', sortable: false , thStyle:  {width: '20%'}},
          
        ];
      }else if (level == 3){
        table = [
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '30%'}},
          { key: 'level_1', label: '', sortable: false , thStyle:  {width: '20%'}},
          { key: 'level_2', label: '', sortable: false , thStyle:  {width: '20%'}},
          { key: 'level_3', label: '', sortable: false , thStyle:  {width: '20%'}},
          { key: 'action', label: '', sortable: false , thStyle:  {width: '10%'}},
          
        ];
      }else if (level == 4){
        table = [
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '30%'}},
          { key: 'level_1', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'level_2', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'level_3', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'level_4', label: '', sortable: false , thStyle:  {width: '15%'}},
          { key: 'action', label: '', sortable: false , thStyle:  {width: '10%'}},
          
        ]
      }else if (level == 5){
        table = [
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '24%'}},
          { key: 'level_1', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'level_2', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'level_3', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'level_4', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'level_5', label: '', sortable: false , thStyle:  {width: '13%'}},
          { key: 'action', label: '', sortable: false , thStyle:  {width: '11%'}},
          
        ]
      }else if (level == 6){
        table = [
          { key: 'item_name', label: 'Item Name', sortable: false , thStyle:  {width: '24%'}},
          { key: 'level_1', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'level_2', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'level_3', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'level_4', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'level_5', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'level_6', label: '', sortable: false , thStyle:  {width: '11%'}},
          { key: 'action', label: '', sortable: false , thStyle:  {width: '10%'}},
          
        ]
      }

      return table;
    },
    locationChange(id,i){

      this.checklistData[0].location != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      
       if (id != '') {

        return this.$store.dispatch(POST_API, {
           data:{
             location:id,
             site:this.site
           },
           api: '/api/qs-location-level-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.checklistData.splice(i,1,data);
                this.checklistData[i].order = i;
                return this.checklistData[i];
            }
        });


      }else{
        var data = {
          location: '',
          location_name:'',
          level_1 : '',
          level_2 : '',
          level_3 : '',
          no_of_levels:3,
          item_data : [],
          db_location : false,
          order: i
        }

        this.checklistData.splice(i,1,data);
        return this.checklistData[i];
      }
               
    },
    isNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      console.log(keyCode);
      if ($event.target.value.length >= 1) {
        $event.preventDefault();
      }else if(keyCode < 49 || keyCode > 53){
        $event.preventDefault();
      }
    },
    addMoreFindings(){
      
      var order = 0;

      if (this.photoFindings.length > 0) {
        var lastIndex = this.photoFindings.length - 1;
        order = this.photoFindings[lastIndex].order + 1;
      }

      var obj = {
          hazard_category: '',
          severity_level:'',
          location : '',
          picture : {
            image:null,
            name:''
          },
          description : '',
          
          action_type:'text',
          action_taken:'',
          action_image:{
            image:null,
            name:''
          },
          date : '',
          status : '',
          order:order,
          db_finding: false
      };

      this.photoFindings.push(obj);
    },
    removeFinding(i) {
      this.photoFindings.splice(i,1);
      if (this.photoFindings.length == 0) {
        this.addMoreFindings();
      }
    },
    removeDBFinding(i,item) {

      var msg = 'Are you sure want to remove this finding record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      hazard_category  : item.hazard_category,
                      severity_level  : item.severity_level,
                      location:item.location,
                      date : this.date,
                      site :this.site,
                      shift : this.shift,
                      month_of_audit : this.month_of_audit,
                  },
                  api : "/api/remove-finding-db-location",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      //localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {

                        this.photoFindings.splice(i,1);
                        if (this.photoFindings.length == 0) {
                          this.addMoreFindings();
                        }  
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },
    uploadImage(value){
      this.photoFindings[value.index].picture = value.image;

      return this.photoFindings;
    },
    uploadActionImage(value){
      this.photoFindings[value.index].action_image = value.image;

      return this.photoFindings;
    },
    resetAction(index){
      this.photoFindings[index].action_taken = '';
      this.photoFindings[index].action_image = {
        image:null,
        name:''
      };
    },
    formSubmit(e){
      e.preventDefault();

      if(this.status == 'draft'){
        this.actualForm();            
      } else {    
        this.decisionAlert('Are you sure want to submit this report ?')
        .then(result => {
          if (result.value) {
            this.actualForm();            
          }
        })
      }
    },
    actualForm(){
    
      return this.$store.dispatch(POST_API, {
        data:{
            id            : this.id,
            date          : this.date,
            site          : this.site,
            shift         : this.shift,
            inspected_by  : this.inspected_by,
            description   : this.description,
            month_of_audit: this.month_of_audit,
            time          : this.time,
            checklistData : this.checklistData,
            photoFindings : this.photoFindings,
            status        : this.status,
            signature     : this.supervisorSign,
            others        : this.others,
            form_id       :this.$route.params.id,
            approve_signature: this.approverSign,
            approve_signature_time: this.approve_signature_time,
            approve_remark: this.approve_remark,
            role:this.$store.getters.currentUser.role,

        },
        api : '/api/submit-quality-safety-assessment'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              this.errorAlertCF()
          } else {
              this.showDismissibleAlert = false;

              var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                /*if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
            
                }else{

                    this.site = '';
                }
                this.siteChange();*/

                if (data != null) {
                    window.open(data, '_blank');
                }
                localStorage.setItem('routeCFCheck','no');
                
                if(this.$route.params.id){
                  this.$router.push({name:'quality-safety-report'})
                }else{

                  this.$router.push({ name:'custom-forms' })
                }
                //window.open(data, '_blank');
                  
              });
          }
      });
    },
    discardItem(id){
      var msg = 'Are you sure want to discard this record?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                  data:{
                      id     : id,
                      status : 'deleted'
                  },
                  api : "/api/change-status-quality-safety-report-data",
              })
              .then(() => {
                  if (this.$store.getters.containsErrors) {
                      this.error_message = this.$store.getters.getErrors;

                      this.showAlert();
                  } else {
                      localStorage.setItem('routeCFCheck','no');
                      this.successAlert()
                      .then(() => {
                          this.flag = true;
                          
                          if(this.$route.params.id){
                            this.$router.push({name:'quality-safety-report'})
                          }else{

                            this.$router.push({ name:'custom-forms' })
                          }
                      });
                  }
              });
          }
      })
      .catch(err => {
          console.log(err);
      })
    },
    updatePhotoStatus(e,i){
      if(e == ''){
        this.photoFindings[i].status = '';
      }else if (e == 'good_practice') {
        this.photoFindings[i].status = 'closed';
      }else {
        this.photoFindings[i].status = 'open';
      }
    },
    goBack(){

      var msg = 'Do you want to Go back without saving your report?';

      this.decisionAlert(msg)
      .then(result => {
          if (result.value) {
            localStorage.setItem('routeCFCheck','no');
            // this.$router.go(-1);
            if(this.$route.params.id){
              this.$router.push({name:'quality-safety-report'})
            }else{

              this.$router.push({ name:'custom-forms' })
            
            }
              

          }
      })
    },
    getFormDetails(){
      /*localStorage.setItem('routeCFCheck','yes');*/

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/get-qsa-report-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.id            = data._id;
                this.date          = data.date;
                this.site          = data.site;
                this.shift         = data.shift;
                this.inspected_by  = data.inspected_by;
                this.description   = data.description;
                this.month_of_audit= data.month_of_audit;
                this.time          = data.time;
                this.checklistData = data.checklistData;
                this.photoFindings = data.photoFindings;
                this.status        = data.status;
                this.supervisorSign     = {
                    image:data.signature,
                    name:'',
                    type : 'supervisor',
                    default: 'yes'
                };
                
                this.others        = data.others;

                var role = this.$store.getters.currentUser.role;

                if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                    //this.disabledOptions = false;
                    this.ledit = true;
                } else if(((role == 'administrator') || (role == 'admin')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                    this.ledit = true;
                }

                this.getShiftData();
                this.getLocations();
            }
        });

    },
    checkdisabled(){
      if (this.ledit == true) {
        return false;
      }else if(this.disabledOptions == true){
        return true;
      }else{
        return false;
      }
    },
    disabledDate(date){
      return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
    },

      
  },
  mounted(){
    
    this.allSites();
    this.createAuditMonth();
    this.useDefault();

    if(this.$route.params.id){
      this.disabledOptions = true;
      this.getFormDetails();
    }
    
  },  
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
